<template>
	<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.tabsMenu.auditRecord')" size="default">
		<a-descriptions-item>
			<a-table
				style="font-size: 12px"
				:columns="columns"
				size="default"
				:rowKey="(record, index) => index"
				:dataSource="dataSource"
				:pagination="pagination"
				@change="handleTableChange"
				bordered
			>
			</a-table>
		</a-descriptions-item>
	</a-descriptions>
</template>
<script>
export default {
	props: ['checkHistories'],
	data() {
		return {
			columns: [
				{
					// 行为
					title: this.$t('table.applyInfo.recordCloums.action'),
					dataIndex: 'nodeAction',
					align: 'center',
					customRender: (v, o) => {
						if (v) {
							return this.$t(`table.applyInfo.recordCloums.status.${v}`)
						} else {
							return ''
						}
					}
				},
				{
					// 操作人
					title: this.$t('table.applyInfo.recordCloums.actioner'),
					dataIndex: 'checkerName',
					align: 'center'
				},
				{
					// 备注
					title: this.$t('table.applyInfo.recordCloums.remark'),
					dataIndex: 'checkFailType',
					align: 'center',
					customRender: (v, o) => {
						if (o.pass === 0 && v) {
							return this.$t(`table.loanReview.Seeting.modal.reasonMap.${v}`) + ': ' + o.checkText
						} else {
							return o.checkText
						}
					}
				},
				{
					// 时间
					title: this.$t('table.applyInfo.recordCloums.time'),
					dataIndex: 'checkTime',
					align: 'center'
				}
			],
			dataSource: [],
			pagination: {
				total: 0,
				// 展示页面总条数
				showTotal: (total, range) => `Total ${total} items`,
				hideOnSinglePage: false,
				current: 1,
				showSizeChanger: true,
				pageSize: 10,
				pageSizeOptions: ['10', '20', '50', '100'],
				onChange: (current, pageSize) => {},
				onShowSizeChange: (current, pageSize) => {}
			}
		}
	},
	created() {
		this.pagination.total = this.checkHistories.length
		this.pagination.current = 1
		this.pagination.pageSize = 10
		this.dataSource = this.getListArr()
	},
	methods: {
		handleTableChange(pagination, filters, sorter) {
			let { current, pageSize } = pagination
			this.pagination.current = current
			this.pagination.pageSize = pageSize
			this.dataSource = this.getListArr()
		},
		getListArr() {
			let current = this.pagination.current,
				pageSize = this.pagination.pageSize,
				listAll = this.checkHistories,
				dataSource = []
			dataSource = listAll.slice((current - 1) * pageSize, current * pageSize)
			return dataSource
		}
	}
}
</script>
