<template>
	<div>
		<a-descriptions bordered :title="$t(`table.finance.repayment.title1`)" size="default">
			<a-descriptions-item :label="$t('table.finance.cloum.loanId')">
				<span>{{ caseId }}</span>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.payAmount')">
				<a-statistic :precision="2" :value="amount == null ? '' : amount" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.payType')">
				<span>{{ this.$t(`table.finance.repayment.payTypeMap.${this.transactionTypeEnum.value}`) }}</span>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.name')">
				<span>{{ fullName }}</span>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.payID')">
				<span>{{ paymentId }}</span>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.amountReceived')">
				<a-statistic :precision="2" :value="amount == null ? '' : amount" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.mobile')">
				<span v-html="mobiles"></span>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.arrivalTime')">
				<span>{{ transactionDate }}</span>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.balance')">
				<a @click="SowReason"> <a-statistic :precision="2" :value="extraAmount == null ? '' : extraAmount" /></a>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.licensePlateNumber')">
				<span v-html="carPlateNo"></span>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.payCode')">
				<a @click="GoPayCode">{{ $t('page.checkDetail') }}</a>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.check')">
				<span>{{ needClosedLoan ? $t('page.yes') : $t('page.no') }}</span>
			</a-descriptions-item>
		</a-descriptions>
		<repayments-plan-list
			v-if="repayPlan.length > 0"
			:repayPlan.sync="repayPlan"
			:insurancePlans.sync="insurancePlans"
			:emergencyPlans.sync="emergencyPlans"
			:overpaidDetail.sync="overpaidDetail"
			style="margin-bottom: 10px"
			:loanType="columnLoanType"
		></repayments-plan-list>
		<div class="remark">tips: {{ $t('table.finance.cloumRepayment.tips') }}</div>
		<a-button type="primary" v-t.preserve="'operate.refused'" style="margin-right: 30px" @click="Refused"></a-button>
		<a-button type="primary" v-t.preserve="'operate.successed'" @click="Successed"></a-button>
		<a-modal v-drag-modal v-model="RefusedModal.show" :title="$t(`operate.refusedReason`)" @ok="SaveRefused">
			<a-textarea v-model="RefusedModal.msg" :maxLength="300" :auto-size="{ minRows: 4, maxRows: 10 }" />
		</a-modal>
		<audit-record v-if="isShow" :checkHistories="checkHistories"></audit-record>
	</div>
</template>
<script>
import AuditRecord from '@/views/businessComponents/AuditRecord'
import { apiLoanTransactionInput, apiProcessHistory, apiLoanTransactionInputPreview, apiHandleTransaction } from '@/api/check'
import RepaymentsPlanList from '@/views/businessComponents/RepaymentsPlanList'
export default {
	data() {
		return {
			id: this.$route.query.id,
			caseId: '',
			loanId: '04c6f3b990800000',
			clientId: '04c83bc8cd800000',
			mobileNo: '072345678',
			mobiles: [],
			fullName: 'test-firsttest-middletest-last',
			carPlateNo: null,
			paymentId: '1',
			paymentAmount: 1.0,
			transactionTypeEnum: {
				value: 1,
				code: 'LoanFeeType.transfer'
			},
			transactionDate: '2021-12-20 05:00:00',
			transactionFiles: 'beescredit-private/04c6f3b990800000/1639964301564.downloan.svg',
			createdDate: '2021-12-20',
			userId: '1',
			amount: 111.0,
			extraAmount: 111.0,
			actualExtraAmount: 111.0,
			extraReason: '1111',
			needClosedLoan: true,
			status: 1,
			isShow: false,
			checkHistories: [],
			columnLoanType: '',
			repayPlan: [],
			insurancePlans: [],
			emergencyPlans: [],
			overpaidDetail: [],
			RefusedModal: {
				show: false,
				msg: ''
			}
		}
	},
	created() {
		this.$nextTick(() => {
			this.Init()
		})
	},
	methods: {
		Init() {
			apiLoanTransactionInput(this.id).then((res) => {
				this.columnLoanType = res.loanType
				this.loanId = res.loanId
				this.caseId = res.casePrefix + '-' + res.caseId
				this.clientId = res.clientId
				this.mobileNo = res.mobileNo
				this.mobiles = res.mobiles ? res.mobiles.join('<br />') : ''
				this.fullName = res.fullName
				this.carPlateNo = res.carInfos ? res.carInfos.map((item) => item.registrationNumber).join('<br />') : ''
				this.paymentId = res.paymentId
				this.paymentAmount = res.paymentAmount
				this.transactionTypeEnum = res.transactionTypeEnum
				this.transactionDate = res.transactionDate
				this.transactionFiles = res.transactionFiles
				this.createdDate = res.createdDate
				this.userId = res.userId
				this.amount = res.amount
				this.extraAmount = res.extraAmount
				this.actualExtraAmount = res.actualExtraAmount
				this.extraReason = res.extraReason
				this.needClosedLoan = res.needClosedLoan
				this.status = res.status
				this.GetRecordList()
			})
			apiLoanTransactionInputPreview({ id: this.id }).then((res) => {
				this.repayPlan = res.loanRepaymentPreviewVos
				this.insurancePlans = res.loanInsurancePreviewVos
				this.emergencyPlans = res.loanEmergencyPreviewVos
				this.overpaidDetail = res.overpaidDetail
			})
		},
		// 查看原因
		SowReason() {
			this.$success({
				title: this.$t('table.finance.repayment.balanceReason'),
				content: this.extraReason
			})
		},
		GoPayCode() {
			let linkurl = this.$Util.getObsImageUrl(this.$store.state.pageState.authObj, this.transactionFiles)
			window.open(linkurl, '_blank')
		},
		// 获取操作记录
		GetRecordList() {
			apiProcessHistory({ loanId: this.loanId }).then((res) => {
				this.checkHistories = res
				this.isShow = true
			})
		},
		// 拒绝平账
		Refused() {
			this.RefusedModal = {
				show: true,
				msg: ''
			}
		},
		// 提交表单
		SaveRefused() {
			let params = {
				pass: false,
				text: this.RefusedModal.msg,
				id: this.id
			}
			if (!params.text) {
				this.$message.error('msg error')
				return false
			}
			apiHandleTransaction(params)
				.then((res) => {
					this.$message.success('success')
					this.RefusedModal.show = false
					this.$store.commit('tabs/delTab', this.$route.fullPath)
					this.$store.commit('tabs/setActiveTab', '/home/finance/repaymentReview')
					this.$router.push('/home/finance/repaymentReview')
				})
				.catch((err) => {
					this.RefusedModal.show = false
				})
		},
		// 平账成功
		Successed() {
			this.$confirm({
				title: this.$t(`table.finance.msg`),
				content: '',
				onOk: () => {
					let params = {
						pass: true,
						id: this.id
					}
					apiHandleTransaction(params)
						.then((res) => {
							this.$message.success('success')
							this.$store.commit('tabs/delTab', this.$route.fullPath)
							this.$store.commit('tabs/setActiveTab', '/home/finance/repaymentReview')
							this.$router.push('/home/finance/repaymentReview')
						})
						.catch((err) => {})
				},
				onCancel() {
					console.log('Cancel')
				}
			})
		}
	},
	components: {
		RepaymentsPlanList,
		AuditRecord
	}
}
</script>
<style scoped lang="less">
.ant-form-item {
	margin-bottom: 10px;
}
.ant-form-item {
	display: flex;
}
.ant-form-item-control-wrapper {
	flex: 1;
}
.ant-table-row.change {
	.link {
		color: #000;
		cursor: pointer;
	}
}
.p_list {
	text-align: center;
}
.link {
	color: #40a9ff;
	cursor: pointer;
}
.remark {
	padding: 10px 0;
	font-size: 16px;
	color: #f56c6c;
}
</style>
